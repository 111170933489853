import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/reevesdotnl/reevesdotnl/src/templates/pdf-view.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PublishDate = makeShortcode("PublishDate");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Eleven former staff members of the ITC location in Delft (that closed in 2001)
got together in Delft  for a lunch to mark Colin Reeves' 70th birthday. `}</p>
    <p>{`The lunch was held at the restaurant Het Rieten Dak in the Delftse Hout where
Colin marked his  50th birthday with most of the present group 20 years ago.
This time it was on September 14, a  few days after Colin's birthday, and the
unseasonably hot weather meant that a shady spot  outdoors was just perfect. `}</p>
    <p>{`Thanks to Margaret Vrolijk who organised the picture below and put it on
Facebook.`}</p>
    <PublishDate mdxType="PublishDate" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      